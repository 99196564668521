import { API_MEDIA_URL } from "@/constants/environment";
import { fileType } from "@/constants/file";
import { ProjectContext } from "@/context/project.context";
import { isImageFile, isVideoFile } from "@/utils";
import { ImageS3 } from "Models";
import { Upload, message } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import axios from "axios";
import clsx from "clsx";
import { ReactNode, useContext, useEffect, useState } from "react";
import PreviewFile from "./PreviewFile";

import { MAX_REVIEW_FILE_UPLOAD } from "@/constants/common-constant";
import dynamic from "next/dynamic";

const ModalPreviewFile = dynamic(import("./ModalPreviewFile"), { ssr: false });
message.config({
  maxCount: 1,
});

interface IListFile extends UploadFile {
  s3Key?: string;
}
export interface IUploadReviewFileProps {
  onChange?: (value: any) => void;
  value?: ImageS3[];
  setLoading?: (val: boolean) => void;
  type?: "upload" | "dragger";
  uploadButtonProps?: ReactNode;
  className?: string;
  showPreview?: boolean;
  loading?: boolean;
}
const UploadReviewFile = ({
  onChange,
  value,
  type = "dragger",
  setLoading,
  uploadButtonProps = null,
  className,
  showPreview = true,
  loading = false,
}: IUploadReviewFileProps) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState<UploadFile>(null);
  const [fileList, setFileList] = useState<IListFile[]>([]);

  const { refUpload } = useContext(ProjectContext);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!isVideoFile(file.name)) {
      setPreviewFile(file);
      setPreviewOpen(true);
    }
  };

  useEffect(() => {
    if (value)
      setFileList(
        value?.map((item) => ({
          uid: item.s3Key,
          ...item,
          status: "done",
        }))
      );
    else setFileList([]);
  }, [value]);

  const handleChange: UploadProps["onChange"] = (data) => {
    const { fileList: files } = data;
    const hasPendingItem = files.find((i) => i.status !== "done");

    const newFileList = files
      .filter((item) => item.status)
      .map((item) => ({
        ...item,
        thumbUrl: item.response?.url
          ? isImageFile(item.name)
            ? (item.response?.url as string)
            : isVideoFile(item.name)
            ? (item.response.screenshots?.[0]?.url as string)
            : (item.response.url as string)
          : null,
        s3Key: item.response?.s3Key,
      }));

    const removeAnyOldList = fileList
      .filter((e) => newFileList.find((item) => item.uid === e.uid))
      .map((e) => {
        const findItem = newFileList.find((item) => item.uid === e.uid);
        if (findItem)
          return {
            ...e,
            s3Key: findItem.s3Key ?? e.s3Key,
          };
        else return e;
      });
    const unionFileList = [...removeAnyOldList];

    // unionBy(newFileList, removeAnyOldList, "uid");

    newFileList.forEach((newItem) => {
      const existingItemIndex = unionFileList.findIndex(
        (oldItem) => oldItem.uid === newItem.uid
      );
      if (existingItemIndex > -1) {
        unionFileList[existingItemIndex] = {
          ...newItem,
          status: newItem.status,
          s3Key: newItem.s3Key ?? unionFileList[existingItemIndex].s3Key,
          url: unionFileList[existingItemIndex].url ?? newItem.url,
          thumbUrl:
            unionFileList[existingItemIndex].thumbUrl ?? newItem.thumbUrl,
        };
      } else unionFileList.push(newItem);
    });

    //

    setFileList(unionFileList);
    if (!hasPendingItem) {
      setLoading(false);
      onChange && onChange(unionFileList);
    } else setLoading(true);
    if (files?.length > MAX_REVIEW_FILE_UPLOAD) {
      setLoading(false);
    }
    // console.log(unionFileList);
    const isMatchFileType = files.find(
      (file) =>
        file.name.match(fileType.file) ||
        file.name.match(fileType.image) ||
        file.name.match(fileType.video)
    );
    if (!isMatchFileType) {
      setLoading(false);
    }

    let totalSize = 0;
    files.forEach((item) => (totalSize += item.size));
    if (totalSize > 1024 * 1024 * 500) {
      setLoading(false);
    }

    const isGT10 = files.find(
      (file) => file.name.match(fileType.image) && file.size > 1024 * 1024 * 10
    );
    if (isGT10) {
      setLoading(false);
    }
  };

  const uploadButton = (
    <div
      className="flex flex-col items-center justify-center"
      id="btn"
      ref={refUpload}>
      {/* <PlusOutlined /> */}
      <i className="mrv mrv-add_photo_alternate text-blue-400 text-fs-20"></i>

      {!fileList.length && type === "dragger" ? (
        <>
          <div className="text-blue-500">Thêm ảnh, video, tài liệu</div>
          <div className="text-grey-500 text-fs-12">
            Kéo thả hoặc chọn ảnh, video, tài liệu để thêm.
          </div>
        </>
      ) : null}
    </div>
  );

  return (
    <>
      <Upload.Dragger
        listType="picture-card"
        className={clsx(
          "transition-all duration-300",
          fileList?.length
            ? "dragger-hase-file"
            : type === "upload"
            ? "!hidden"
            : "",
          className
        )}
        // className={clsx(fileList?.length ? "" : "!hidden")}
        fileList={fileList}
        onPreview={showPreview ? handlePreview : null}
        onChange={handleChange}
        accept={`.jpg,.jpeg,.png,.bmp,.heic,.heif,.tiff,.svg,.psd,.webp,.avif,.mp4,.avi,.mkv,.wmv,.vob,.flv,.wmv9,.mpeg,.3gp,.webm,.hevc,.mov,.mpg,.3gpp,.mpeg1,.mpeg2,.mpeg4,.mpegps,.pdf,.xlsx,.xls,.doc,.docx,.pptx,.dwg,.kmz,.wma,.pdf,.xlsx,.xls,.doc,.docx,.pptx`}
        multiple
        itemRender={(
          originNode,
          file,
          fileList,
          { remove, preview, download }
        ) => {
          return (
            <PreviewFile
              originNode={originNode}
              showPreview={showPreview}
              file={file}
              fileList={fileList}
              functionList={{ remove, preview, download }}
              loading={loading}
            />
          );
        }}
        showUploadList={
          fileList?.length
            ? {
                removeIcon: (
                  <span className="anticon !text-white">
                    <i className="mrv mrv-close"></i>
                  </span>
                ),
              }
            : false
        }
        beforeUpload={(file, files) => {
          let totalSize = 0;
          fileList.forEach((item) => (totalSize += item.size));

          return new Promise((resolve, reject) => {
            // const isLt2M = file.size / 1024 / 1024 < 10;
            if (
              file.name.match(fileType.image) &&
              file.size > 1024 * 1024 * 10
            ) {
              message.error("Ảnh  dung lượng tối đa 10MB");
              reject(false);
            }

            if (totalSize + file.size > 1024 * 1024 * 500) {
              reject(false);
              message.error("Tổng dung lượng tối đa 500MB");
            }
            // if (!isLt2M) {
            //   message.error("Một file dung lượng tối đa 10MB");
            //   reject(false);
            // }
            else if (files.length + fileList?.length > MAX_REVIEW_FILE_UPLOAD) {
              reject(false);
              message.error(`Upload tối đa ${MAX_REVIEW_FILE_UPLOAD} files`);
            } else if (
              file.name.match(fileType.file) ||
              file.name.match(fileType.image) ||
              file.name.match(fileType.video)
            )
              resolve(true);
            else {
              reject(false);
              message.error("Định dạng không được hỗ trợ");
            }
          });
        }}
        customRequest={async ({
          onSuccess,
          onError,
          file,
          filename,
          data,
          onProgress,
        }) => {
          const fmData = new FormData();
          let field = "";
          if ((file as RcFile).name.match(fileType.image)) field = "images";
          if ((file as RcFile).name.match(fileType.video)) field = "videos";
          if ((file as RcFile).name.match(fileType.file)) field = "files";
          fmData.append(field, file);
          fmData.append("screenshot", "true");

          try {
            const response = await axios.request<ImageS3[]>({
              method: "post",
              baseURL: API_MEDIA_URL,
              url: "v1/media",
              onUploadProgress: function (progressEvent) {
                onProgress({
                  percent: (100 * progressEvent.loaded) / progressEvent.total,
                });
              },
              headers: {
                "X-Client-Source": "meeyreview",
                "Client-ID": "meeyreview",
              },
              data: fmData,
            });

            onSuccess(response.data?.[field]?.[0]);
          } catch (err: any) {
            onError(err);
          } finally {
          }
        }}
        disabled={loading}
        // beforeUpload={beforeUpload}
      >
        {fileList.length >= 50 ? null : uploadButtonProps || uploadButton}
      </Upload.Dragger>
      <ModalPreviewFile
        handleCancel={handleCancel}
        previewFile={previewFile}
        previewOpen={previewOpen}
      />
    </>
  );
};

export default UploadReviewFile;
