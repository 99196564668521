import { fileType } from "@/constants/file";
import { isVideoFile } from "@/utils";
import { Progress, UploadFile } from "antd";
import clsx from "clsx";
import MeeyImage from "../MeeyImage";
// interface PreviewFileProps{

// }

const PreviewFile = ({
  originNode,
  file,
  fileList,
  showPreview = true,
  loading = false,
  functionList: { remove, preview, download },
}: {
  originNode: React.ReactElement;
  file: UploadFile<any>;
  fileList: Array<UploadFile<any>>;
  functionList: any;
  showPreview?: boolean;
  loading?: boolean;
}) => {
  return (
    <div
      className={clsx(
        "relative group cursor-pointer",
        file.name.match(fileType.image) || file.name.match(fileType.video)
          ? "can-preview-upload"
          : "default-file"
      )}
      onClick={() => {
        if (!loading) {
          preview();
        }
      }}>
      {file.status !== "done" ? (
        <div className="w-20 h-20 bg-grey-200 rounded-md flex flex-col relative cursor-pointer">
          <div className="flex-[0_0_36px] w-full flex items-center">
            <div className="-rotate-90 w-fit mr-2">
              <i className="mrv mrv-attachment text-fs-20 "></i>
            </div>
            <div>.{file.name.split(".")?.pop()}</div>
          </div>
          <div className="bg-grey-300 flex-[1_1_auto]  text-fs-12 py-1.5 px-2 overflow-hidden  rounded-md">
            <span className="line-clamp-1">{file.name}</span>
          </div>
        </div>
      ) : file.name.match(fileType.image) ? (
        <div className="w-20 h-20 bg-grey-200 rounded-md flex flex-col relative cursor-pointer">
          {/* <div className="absolute w-full h-full top-0">
            <div
              className="absolute top-1 right-1 w-6 h-6  border-2 bg-[rgba(0,0,0,0.8)] border-white rounded-full flex items-center justify-center"
              onClick={(e) => {
                remove();
              }}>
              <i className="mrv mrv-close text-white text-fs-12 font-bold"></i>
            </div>
          </div> */}

          <div className="text-fs-12 w-full h-full relative overflow-hidden  rounded-md">
            <MeeyImage
              src={file.thumbUrl}
              alt={file.name}
              className="absolute w-full h-full object-cover"
              resizeOnFly={false}
              resizeOptions={{ width: 0, height: 0 }}
            />
          </div>
        </div>
      ) : file.name.match(fileType.video) ? (
        <div className="w-20 h-20 bg-grey-200 rounded-md flex flex-col relative cursor-pointer">
          <div className="  text-fs-12 w-full h-full overflow-hidden  rounded-md">
            {file.status === "done" ? (
              <MeeyImage
                src={file.thumbUrl}
                alt={file.name}
                resizeOnFly={false}
                resizeOptions={{ width: 0, height: 0 }}
              />
            ) : (
              <div className="flex-[0_0_36px] w-full flex items-center">
                <div className="-rotate-90 w-fit mr-2">
                  <i className="mrv mrv-attachment text-fs-20 "></i>
                </div>
                <div>.{file.name.split(".")?.pop()}</div>
              </div>
            )}
            {file.status === "done" ? (
              <div className="absolute top-1/2 left-1/2  -translate-y-1/2 -translate-x-1/2">
                <i className="mrv mrv-play_arrow text-white text-fs-24 font-bold"></i>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="w-20 h-20 bg-grey-200 rounded-md flex flex-col relative cursor-pointer border border-transparent border-dashed">
          {/* <div className="absolute w-full h-full top-0">
            <div className="absolute top-1 right-1 w-6 h-6  border-2 bg-[rgba(0,0,0,0.8)] border-white rounded-full flex items-center justify-center">
              <i className="mrv mrv-close text-white text-fs-12 font-bold"></i>
            </div>
          </div> */}
          <div className="flex-[0_0_36px] w-full flex items-center">
            <div className="-rotate-90 w-fit mr-2">
              <i className="mrv mrv-attachment text-fs-20 "></i>
            </div>
            <div>.{file.name.split(".")?.pop()}</div>
          </div>
          <div className="bg-grey-300 flex-[1_1_auto]  text-fs-12 py-1.5 px-2 overflow-hidden  rounded-md">
            <span className="line-clamp-2">{file.name}</span>
          </div>
        </div>
      )}

      {file.status === "uploading" || file.status === "done" ? (
        <div className="absolute bottom-0 left-0 w-full ">
          <Progress
            className="mb-0 mr-0 px-1"
            percent={file.percent}
            size="small"
            status={
              file.status === "uploading"
                ? "active"
                : file.status === "done"
                ? "success"
                : "exception"
            }
            showInfo={false}
          />
        </div>
      ) : null}
      {file.status === "done" && !isVideoFile(file.name) && showPreview ? (
        <div
          onClick={() => {
            // preview();
          }}
          className="cursor-pointer absolute w-full h-full top-0 z-10 bg-[rgba(0,0,0,0.4)] flex items-center justify-center opacity-0 group-hover:opacity-100  rounded-md duration-300">
          <i className="mrv mrv-preview text-white text-fs-20"></i>
        </div>
      ) : null}

      <div className="absolute w-full h-full top-0 z-[15] opacity-0 group-hover:opacity-100 duration-300">
        <div
          className="absolute top-1 right-1 w-6 h-6  border-2 bg-[rgba(0,0,0,0.8)] border-white rounded-full flex items-center justify-center"
          onClick={(e) => {
            if (!loading) {
              e.stopPropagation();
              remove();
            }
          }}>
          <i className="mrv mrv-close text-white text-fs-12 font-bold"></i>
        </div>
      </div>
    </div>
  );
};

export default PreviewFile;
